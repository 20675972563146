
.section {
    position: relative;
}
.business-card {
    border-radius: 16px !important;
    margin: 0 auto;
    height: 100%;
    .v-card {
        &__text {
            word-break: keep-all;
        }
    }
}
.service-card {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 40px;
    .v-card {
        &__title,
        &__text {
            padding-left: 0;
            padding-right: 0;
        }
        &__title {
            padding-top: 0;
        }
        &__text {
            word-break: keep-all;
        }
    }
}
.bottom-banner {
    aspect-ratio: 16/9;
    display: flex;
    align-items: center;
    padding: 20px 0;
    &__title {
        font-size: 30px;
        > span {
            background-color: #ff8489;
            color: #fff;
            padding: 4px 0;
        }
    }
}
@media (min-width: 768px) {
    .business-card {
        .v-card {
            &__title,
            &__text {
                padding-left: 28px;
                padding-right: 28px;
            }
            &__title {
                padding-top: 40px;
                padding-bottom: 24px;
            }
            &__text {
                padding-bottom: 40px;
            }
        }
    }
    .service-card {
        .v-card {
            &__title,
            &__text {
                padding-bottom: 24px;
            }
        }
    }
    .bottom-banner {
        aspect-ratio: 1920/600;
        display: flex;
        align-items: center;
        &__title {
            font-size: 44px;
            > span {
                padding: 6px 0;
            }
        }
    }
}
